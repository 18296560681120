.contentWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.content {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
}

.illustration {
  display: none;
}

@media only screen and (max-width: 841px) {
  .illustration {
    display: block;
    margin: 0 auto;
  }
}

.pageTitle {
  font-size: 3.125rem !important;
}

.link {
  color: var(--color-marinaBlue-50);
}
