.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sideBySideQuestions {
  display: flex;
  gap: 24px;
}

.sideBySideQuestion {
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .sideBySideQuestions {
    flex-direction: column;
  }
}

.checkboxContainer {
  margin-top: 64px;

  div:last-of-type:not(:first-of-type) {
    margin-bottom: 8px;
  }
}

.checkboxLinks {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 46px;
  list-style: disc;
  color: var(--color-marinaBlue-50);
}

.checkboxLink {
  &:hover {
    color: var(--color-marinaBlue-90);
  }

  a {
    width: max-content;
  }
}
