.container {
  display: flex;
  min-height: 100vh;
  height: 100%;
}

@media only screen and (max-width: 1023px) {
  .sideBannerContainer {
    display: none;
  }
}

@media only screen and (min-height: 1023px) and (orientation: portrait) {
  .sideBannerContainer {
    display: none;
  }
}
