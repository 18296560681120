.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  padding: 80px;
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.anchorageLogo {
  height: 40px;
  width: auto;
  fill: var(--color-white);
  display: none;
}

.footer {
  width: 100%;
}

.footerMessage {
  display: none;
  width: 100%;
  color: var(--color-marinaBlue-50);
}

.footerMessageLink {
  font-weight: bold;
  font-size: 12px;
}

@media only screen and (max-width: 599px) {
  .container {
    padding: 40px 16px;
  }
  .footerMessage {
    justify-content: center;
  }
}

@media only screen and (max-width: 1023px) {
  .anchorageLogo {
    display: block;
  }
  .footerMessage {
    display: flex;
  }
}

@media only screen and (min-width: 600px) and (max-width: 841px) {
  .firstPage {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media only screen and (min-height: 1023px) and (orientation: portrait) {
  .anchorageLogo {
    display: block;
  }
  .footerMessage {
    display: flex;
  }
}
