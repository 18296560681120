.container {
  background: var(--color-marinaBlue-50);
  height: 100%;
  position: relative;
}

.contentContainer {
  width: 589px;
  padding: 40px;
  height: 100vh;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
}

.anchorageLogo {
  height: 40px;
  width: auto;
  fill: var(--color-black);
}

.image {
  width: 395px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  color: var(--color-black);
}

.footerLink {
  color: var(--color-black);
  font-weight: bold;
}

.pageTitle {
  color: var(--color-black);
}
