.container {
  display: flex;
  width: 100%;
}

.singleButton {
  justify-content: flex-end;
}

.doubleButton {
  justify-content: space-between;
}

@media only screen and (max-width: 599px) {
  .firstPageSingleButton {
    justify-content: center;
    margin-bottom: 24px;
  }
}
